<template>
  <div class="monitors">
    <div class="body">
      <div id="product-list" class="box cc-box cc-box-body">
        <div class="box-body">
            <h4 class="Compra-Direta mx-4">Compra Direta</h4>
            <div class="row" >
                <div class="col d-flex justify-content-start">
                    <div class="form-group mx-4">
                        <label for="empresaInput">Empresa (OBRIGATÓRIO)</label>
                        <select class="form-control input-Compra" id="empresaInput" style="width:200%">
                        <option>Selecione uma empresa</option>
                        </select>
                    </div>
                </div>
                <div class="col d-flex flex-row justify-content-between">
                    <div class="form-group flex-grow-2 mx-2">
                        <label for="fornecedorInput">Filtros</label>
                        <select class="form-control input-Compra " id="fornecedorInput">
                        <option>Tipo de fornecedor</option>
                        </select>
                    </div>
                    <div class="form-group" style="padding-top: 29px !important">
                        <select class="form-control input-Compra ">
                        <option>Estado (UF)</option>
                        </select>
                    </div>
                     <div class="form-group flex-grow-1 mx-2" style="padding-top: 29px !important">
                        <select class="form-control input-Compra">
                        <option>Cidade</option>
                        </select>
                    </div>
                    <div class="" style="padding-top: 15px !important">
                        <button class="btn botaoLimpaFiltro">Limpar Filtros</button>
                    </div>
                    
                </div>
            </div>
            <br>
            <div class="row border-compra py-5  ml-4" style="width: 98.4% !important">
              <div class="col d-flex justify-content-start">
                <h4 class="Compra-Direta">Selecione um fornecedor</h4>
              </div>
              <div class="col d-flex justify-content-end">
               <button class="btn-Gray-Compra mx-3">Limpar Seleção</button>
                <router-link class="btn-Blue-Compra" to="/selecaoProdutos"> > Ir para seleção de produtos</router-link>
              </div>
            </div>
            <div class="row mx-2">
              <div class="col-md-2 col-lg-2 bordaImagens p-1">
                <img class="bordaImagens" src="https://via.placeholder.com/150" alt="">
              </div>
              <div class="col-md-2 col-lg-2 bordaImagens p-1">
                 <img class="bordaImagens" src="https://via.placeholder.com/150" alt="">
              </div>
              <div class="col-md-2 col-lg-2 bordaImagens p-1">
                  <img class="bordaImagens" src="https://via.placeholder.com/150" alt="">
              </div>
              <div class="col-md-2 col-lg-2 bordaImagens p-1">
                  <img class="bordaImagens" src="https://via.placeholder.com/150" alt="">
              </div>
              <div class="col-md-2 col-lg-2 bordaImagens p-1">
                 <img class="bordaImagens" src="https://via.placeholder.com/150" alt="">
              </div>
              <div class="col-md-2 col-lg-2 bordaImagens p-1">
                 <img class="bordaImagens" src="https://via.placeholder.com/150" alt="">
              </div>
               <div class="col-md-2 col-lg-2 bordaImagens p-1">
                 <img class="bordaImagens" src="https://via.placeholder.com/150" alt="">
              </div>
              <div class="col-md-2 col-lg-2 bordaImagens p-1">
                  <img class="bordaImagens" src="https://via.placeholder.com/150" alt="">
              </div>
              <div class="col-md-2 col-lg-2 bordaImagens p-1">
                 <img class="bordaImagens" src="https://via.placeholder.com/150" alt="">
              </div>
              <div class="col-md-2 col-lg-2 bordaImagens p-1">
                 <img class="bordaImagens" src="https://via.placeholder.com/150" alt="">
              </div>
              <div class="col-md-2 col-lg-2 bordaImagens p-1">
                 <img class="bordaImagens" src="https://via.placeholder.com/150" alt="">
              </div>
              <div class="col-md-2 col-lg-2 bordaImagens p-1">
                 <img class="bordaImagens" src="https://via.placeholder.com/150" alt="">
              </div>
            </div>
        </div>
      </div>
      
    </div>
</div>
</template>
<script>
import Header from "../base-components/Header";
import Breadcrumb from "@/components/shared/Breadcrumb";

export default {
  data() {
    return {
       page_error: false,
        paginaAtual: 1,
      totalPaginas: 1,
      breadcrumbs : [
        {
          name: 'Compra Direta'
        }
      ]
    };
  },
}
</script>
<style scoped>
.body{
   width: calc(97%) !important;
}
.Compra-Direta {
  opacity: 0.7;
  font-family: Rubik;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  text-align: left;
  color: #707070;
  
}
.input-Compra {
  height: 35px !important;
  border-radius: 4px !important;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.05) !important;
  border: solid 1px #d7dae2 !important;
  background-color: #ffffff !important;
  font-size: 13px !important;
  font-weight: normal !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.54 !important;
  letter-spacing: normal !important;
  text-align: left !important;
  color: #4d4f5c !important;
  padding: 5px !important;
  font-family: "Rubik";
  margin:2px !important;
}
.border-compra{
  border-top: 1px solid #f1f1f1;
  
}
.bordaImagens{
  border: 1px solid lightgray;
  height: 100%; 
  width: 100%; 
  object-fit: cover
  ;
}
.btn-Blue-Compra {
  border-radius: 5px;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #1194a3;
  background-color: #14abbd;
   font-family: Rubik;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.07;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
  padding: 15px
}
.btn-Gray-Compra {
  border-radius: 5px;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px white;
  background-color: white;
   font-family: Rubik;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.07;
  letter-spacing: normal;
  text-align: left;
  color: #707070;
  padding: 15px
}
</style>